
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  watch,
  onBeforeMount,
} from 'vue';
import { mapState, useStore } from 'vuex';
import { SearchData } from '@/models/search.model';
import { search, ellipsisVertical } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { EsService } from '@/services/es.service';
import { OrderingTypes, SearchTypes } from '@/models/enums.model';
import { Document } from '@/models/document.model';
import JnDoc from '../../components/DocCard.vue';
import JnGlobalSearchParams from './GlobalSearchParams.vue';
import JnBancaDatiSearchParams from './BancaDatiSearchParams.vue';
import JnAreaTematicaSearchParams from './AreaTematicaSearchParams.vue';
import JnMassimarioSearchParams from './MassimarioSearchParams.vue';
import * as eventBus from '../../services/event.bus.js';

addIcons({
  search: search,
  'ellipsis-vertical': ellipsisVertical,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonInput,
  IonIcon,
  IonItemDivider,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonItem,
  IonFab,
  IonFabButton,
  IonLabel,
  IonLoading,
  modalController,
} from '@ionic/vue';
export default defineComponent({
  name: 'JnSearch',
  components: {
    IonPage,
    IonToolbar,
    //   IonButton,
    //  IonInput,
    IonHeader,
    //  IonIcon,
    IonContent,
    // IonGrid,
    //  IonRow,
    //  IonCol,
    IonList,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonFab,
    IonFabButton,
    IonIcon,
    JnDoc,
    IonLabel,
    IonLoading,
  },

  setup() {
    const store = useStore();
    const isDisabled = ref(false);
    const docs = ref([] as Document[]);
    const docsSize = ref();

    const loading = ref(true);
    const timeout = ref(5000);

    const isSearchParamsOpen = ref(false);

    const searchData = ref(
      new SearchData({
        index: process.env.VUE_APP_JURANET_INDEXES,
        from: 0,
        size: 10,
        orderingType: OrderingTypes.DATA_CREAZIONE,
        searchType: SearchTypes.ALL,
        searchDescription: 'Ricerca',
        searchInMassime: false
      })
    );

    const pushDocuments = async () => {
      loading.value = true;
      searchData.value.from = docs.value.length;
      const newDocs = await EsService.getDocs(searchData.value as SearchData);
      for (let i = 0; i < newDocs.length; i++) {
        docs.value.push(newDocs[i]);
      }
      loading.value = false;
    };

    const search = async (data: SearchData) => {
      docs.value = [];
      searchData.value = data;
      pushDocuments();
    };

    onMounted(async () => {
      eventBus.default.$on('reset-results', function(data: string) {
        docs.value = [];
      });
      eventBus.default.$on('global-search-data', function(data: SearchData) {
        search(data);
      });
      eventBus.default.$on('banca-dati-search-data', function(
        data: SearchData
      ) {
        search(data);
      });
      eventBus.default.$on('area-tematica-search-data', function(
        data: SearchData
      ) {
        search(data);
      });
      eventBus.default.$on('news-search-data', function(data: SearchData) {
        search(data);
      });
      eventBus.default.$on('massimario-search-data', function(
        data: SearchData
      ) {
        search(data);
      });
      searchData.value = store.getters.searchData;
      pushDocuments();
    });

    const loadDocuments = async (ev: CustomEvent) => {
      setTimeout(() => {
        pushDocuments();
        const target = ev.target as HTMLIonInfiniteScrollElement;
        target.complete();
        if (docs.value.length == docsSize.value) {
          target.disabled = true;
        }
      }, 500);
    };

    const openSearchParams = async () => {
      let searchParamsComponent: any;
      if (searchData.value.searchType == SearchTypes.ALL) {
        searchParamsComponent = JnGlobalSearchParams;
      }
      if (searchData.value.searchType == SearchTypes.BANCA_DATI) {
        searchParamsComponent = JnBancaDatiSearchParams;
      }
      if (searchData.value.searchType == SearchTypes.AREE_TEMATICHE) {
        searchParamsComponent = JnAreaTematicaSearchParams;
      }
      if (searchData.value.searchType == SearchTypes.MASSIMARIO) {
        searchParamsComponent = JnMassimarioSearchParams;
      }
      const modal = await modalController.create({
        component: searchParamsComponent,
        componentProps: { searchData: searchData.value },
        cssClass: 'searchModal',
      });

      modal.present();
      const result = await modal.onDidDismiss();
      if (result.data) {
        searchData.value = result.data.value as SearchData;
        store.commit('searchData', searchData);
      }

      docs.value = [];
      pushDocuments();
    };

    return {
      isDisabled,
      loadDocuments,
      isSearchParamsOpen,
      openSearchParams,
      hasDocs: computed(() => docs.value && docs.value.length > 0),
      docs,
      searchData,
      loading,
      timeout,
    };
  },
});
