<template>
  <ion-page>
    <ion-header class="ion-text-center">
      <ion-toolbar>
        <ion-title>
          Parametri di ricerca
        </ion-title>
        <ion-button @click="close()" color="primary" fill="clear" slot="end">
          <ion-icon icon="close" />
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="secondary" @click="search()">
          <ion-icon name="play" />
        </ion-fab-button>
      </ion-fab>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-input type="search" v-model="optText">
              <ion-icon class="ion-padding-start" name="search" />
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-radio-group v-model="optType">
              <ion-grid>
                <ion-row>
                  <ion-col
                    v-for="qt of tipiQuery"
                    :key="qt.value"
                    size="4"
                    class="ion-no-padding"
                  >
                    <ion-item lines="none">
                      <ion-label class="ion-text-center">{{
                        qt.label
                      }}</ion-label>
                      <ion-radio :value="qt.value"></ion-radio>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-radio-group>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Ambito</ion-label>
              <ion-select v-model="optArea" interface="popover" lines="none">
                <ion-select-option
                  v-for="a of aree"
                  :key="a.value"
                  :value="a.value"
                  >{{ a.label }}</ion-select-option
                >
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Ordina per</ion-label>
              <ion-select
                v-model="optTipoOrdinamento"
                interface="popover"
                lines="none"
              >
                <ion-select-option
                  v-for="o of tipiOrdinamento"
                  :key="o.value"
                  :value="o.value"
                  >{{ o.label }}</ion-select-option
                >
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Numero risultati</ion-label>
              <ion-select
                v-model="optNumRisultati"
                interface="popover"
                lines="none"
              >
                <ion-select-option
                  v-for="r of numRisultati"
                  :key="r.value"
                  :value="r.value"
                  >{{ r.label }}</ion-select-option
                >
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-alert-controller></ion-alert-controller>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { Picklist } from '../../models/picklist.model';
import { document, close, play } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { JnService } from '../../services/jn.service';

import {
  SearchTypes,
  QueryTypes,
  OrderingTypes,
  CategoriesMassimario,
  Categories,
} from '../../models/enums.model';

addIcons({
  document: document,
  close: close,
  play: play,
});

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonIcon,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonButton,
  modalController,
  IonFab,
  IonFabButton,
  alertController,
} from '@ionic/vue';
import { SearchData } from '@/models/search.model';

export default defineComponent({
  name: 'JnGlobalSearchParams',
  components: {
    IonContent,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    IonIcon,
    IonInput,
    IonToolbar,
    IonHeader,
    IonPage,
    IonTitle,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonFab,
    IonFabButton,
  },

  setup(props) {
    const store = useStore();

    const optArea = ref(SearchTypes.ALL);
    const optText = ref('');
    const optType = ref(QueryTypes.OR);
    let optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;

    // const optTipoOrdinamento = ref<OrderingTypes>();
    let optNumRisultati = '20';

    const tipiQuery = ref([] as Array<Picklist>);
    const aree = ref([] as Array<Picklist>);
    const tipiOrdinamento = ref([] as Array<Picklist>);
    const numRisultati = ref([] as Array<Picklist>);

    const searchData = ref(
      new SearchData({
        index: process.env.VUE_APP_ALL_INDEXES,
        from: 0,
        size: 10,
        orderingType: OrderingTypes.DATA_CREAZIONE,
        searchType: SearchTypes.ALL,
        searchDescription: 'Globale',
        searchInMassime: false
      })
    );

    onMounted(async () => {
      tipiQuery.value = JnService.getQueryTypes();
      aree.value = JnService.getTipiRicercaGlobale();
      tipiOrdinamento.value = JnService.getTipiOrdinamento();
      numRisultati.value = JnService.getNumeroRisultati();
    });

    const search = async () => {
      if (!optText.value) {
        const alert = await alertController.create({
          header: 'Ricerca per chiave',
          message: "E' necessario indicare almeno una chiave di ricerca",
          buttons: ['Chiudi'],
        });

        await alert.present();
        return;
      }
      searchData.value.keywords = optText.value;
      searchData.value.queryType = optType.value;
      if (optTipoOrdinamento) {
        searchData.value.orderingType = optTipoOrdinamento;
      }
      if (optNumRisultati) {
        searchData.value.size = Number(optNumRisultati);
      }

      if (optArea.value == SearchTypes.NEWS) {
        searchData.value.index = process.env.VUE_APP_JURANEWS_INDEXES;
        searchData.value.idCategory = [
          Categories.ULTIME_NEWS,
          Categories.APPROFONDIMENTI,
        ];
      } else if (optArea.value == SearchTypes.AREE_TEMATICHE) {
        searchData.value.index = process.env.VUE_APP_JURANEWS_INDEXES;
        searchData.value.idCategory = [
          Categories.COMMENTI,
          Categories.SCHEDA_AUTORALE,
          Categories.CONCORSI,
          Categories.SINTESI_GIURISPRUDENZIALI,
        ];
      } else if (optArea.value == SearchTypes.BANCA_DATI) {
        searchData.value.idCategory = undefined;
        searchData.value.index = process.env.VUE_APP_JURANET_INDEXES;
      } else if (optArea.value == SearchTypes.MASSIMARIO) {
        searchData.value.index = process.env.VUE_APP_JURANEWS_INDEXES;
        searchData.value.idCategory = CategoriesMassimario;
      } else if (optArea.value == SearchTypes.CODICI) {
        searchData.value.idCategory =  [
          Categories.CODICI,
        ];
        searchData.value.index = process.env.VUE_APP_JURANEWS_INDEXES;
      } 
      else {
        searchData.value.idCategory = undefined;
        searchData.value.index = process.env.VUE_APP_ALL_INDEXES;
      }
      modalController.dismiss(searchData);
    };

    const close = async () => {
      modalController.dismiss(undefined);
    };

    const clear = async () => {
      optText.value = '';
      optType.value = QueryTypes.OR;
      optArea.value = SearchTypes.ALL;
      optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;
      optNumRisultati = '20';
    };

    return {
      tipiQuery,
      aree,
      optArea,
      optText,
      optType,
      optTipoOrdinamento,
      optNumRisultati,
      tipiOrdinamento,
      numRisultati,
      search,
      close,
    };
  },
});
</script>
