
import { defineComponent, onMounted, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { Picklist } from '../../models/picklist.model';
import { document, close, play } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { JnService } from '../../services/jn.service';

import {
  SearchTypes,
  QueryTypes,
  OrderingTypes,
  CategoriesMassimario,
  Categories,
} from '../../models/enums.model';

addIcons({
  document: document,
  close: close,
  play: play,
});

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonIcon,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonButton,
  modalController,
  IonFab,
  IonFabButton,
  alertController,
} from '@ionic/vue';
import { SearchData } from '@/models/search.model';

export default defineComponent({
  name: 'JnGlobalSearchParams',
  components: {
    IonContent,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    IonIcon,
    IonInput,
    IonToolbar,
    IonHeader,
    IonPage,
    IonTitle,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonFab,
    IonFabButton,
  },

  setup(props) {
    const store = useStore();

    const optArea = ref(SearchTypes.ALL);
    const optText = ref('');
    const optType = ref(QueryTypes.OR);
    let optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;

    // const optTipoOrdinamento = ref<OrderingTypes>();
    let optNumRisultati = '20';

    const tipiQuery = ref([] as Array<Picklist>);
    const aree = ref([] as Array<Picklist>);
    const tipiOrdinamento = ref([] as Array<Picklist>);
    const numRisultati = ref([] as Array<Picklist>);

    const searchData = ref(
      new SearchData({
        index: process.env.VUE_APP_ALL_INDEXES,
        from: 0,
        size: 10,
        orderingType: OrderingTypes.DATA_CREAZIONE,
        searchType: SearchTypes.ALL,
        searchDescription: 'Globale',
        searchInMassime: false
      })
    );

    onMounted(async () => {
      tipiQuery.value = JnService.getQueryTypes();
      aree.value = JnService.getTipiRicercaGlobale();
      tipiOrdinamento.value = JnService.getTipiOrdinamento();
      numRisultati.value = JnService.getNumeroRisultati();
    });

    const search = async () => {
      if (!optText.value) {
        const alert = await alertController.create({
          header: 'Ricerca per chiave',
          message: "E' necessario indicare almeno una chiave di ricerca",
          buttons: ['Chiudi'],
        });

        await alert.present();
        return;
      }
      searchData.value.keywords = optText.value;
      searchData.value.queryType = optType.value;
      if (optTipoOrdinamento) {
        searchData.value.orderingType = optTipoOrdinamento;
      }
      if (optNumRisultati) {
        searchData.value.size = Number(optNumRisultati);
      }

      if (optArea.value == SearchTypes.NEWS) {
        searchData.value.index = process.env.VUE_APP_JURANEWS_INDEXES;
        searchData.value.idCategory = [
          Categories.ULTIME_NEWS,
          Categories.APPROFONDIMENTI,
        ];
      } else if (optArea.value == SearchTypes.AREE_TEMATICHE) {
        searchData.value.index = process.env.VUE_APP_JURANEWS_INDEXES;
        searchData.value.idCategory = [
          Categories.COMMENTI,
          Categories.SCHEDA_AUTORALE,
          Categories.CONCORSI,
          Categories.SINTESI_GIURISPRUDENZIALI,
        ];
      } else if (optArea.value == SearchTypes.BANCA_DATI) {
        searchData.value.idCategory = undefined;
        searchData.value.index = process.env.VUE_APP_JURANET_INDEXES;
      } else if (optArea.value == SearchTypes.MASSIMARIO) {
        searchData.value.index = process.env.VUE_APP_JURANEWS_INDEXES;
        searchData.value.idCategory = CategoriesMassimario;
      } else if (optArea.value == SearchTypes.CODICI) {
        searchData.value.idCategory =  [
          Categories.CODICI,
        ];
        searchData.value.index = process.env.VUE_APP_JURANEWS_INDEXES;
      } 
      else {
        searchData.value.idCategory = undefined;
        searchData.value.index = process.env.VUE_APP_ALL_INDEXES;
      }
      modalController.dismiss(searchData);
    };

    const close = async () => {
      modalController.dismiss(undefined);
    };

    const clear = async () => {
      optText.value = '';
      optType.value = QueryTypes.OR;
      optArea.value = SearchTypes.ALL;
      optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;
      optNumRisultati = '20';
    };

    return {
      tipiQuery,
      aree,
      optArea,
      optText,
      optType,
      optTipoOrdinamento,
      optNumRisultati,
      tipiOrdinamento,
      numRisultati,
      search,
      close,
    };
  },
});
